.div1 {
    flex: 1;
    position: relative;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 45px;
}
.iconEyeOutlined {
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}
.button,
.iconEyeOutlined {
    position: relative;
}
.buttonprimarydefault {
    border-radius: 28px;
    background-color: #fe9923;
    border: 1px solid #fe9923;
    box-sizing: border-box;
    width: 85px;
    height: 34px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 4px;
}
.button1 {
    position: relative;
    color: #fe9923;
}
.buttonprimarydefaultParent,
.parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.buttonprimarydefaultParent {
    padding: 0 24px;
    font-size: 14px;
    color: #fff;
}
.parent {
    align-self: stretch;
}
.frameChild {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid rgba(102, 102, 102, 0.5);
    box-sizing: border-box;
    width: 16px;
    height: 16px;
}
.frameItem {
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 1px;
    background-color: #fe9923;
    width: 8px;
    height: 8px;
}
.rectangleParent {
    position: relative;
    width: 16px;
    height: 16px;
}
.checkBoxInner {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0;
}
.checkBox,
.checkBoxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkBox {
    flex: 1;
    justify-content: flex-start;
    padding: 0 24px;
    gap: 8px;
}
.checkBoxWrapper {
    align-self: stretch;
    justify-content: center;
}
.frameInner {
    position: relative;
    border-radius: 3px;
    background-color: #fe9923;
    width: 16px;
    height: 16px;
    z-index: 0;
}
.vectorIcon {
    position: absolute;
    margin: 0 !important;
    top: 7px;
    left: 3px;
    width: 10px;
    height: 8px;
    z-index: 1;
}
.rectangleGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0;
    position: relative;
    gap: 10px;
}
.div3 {
    position: relative;
    display: none;
}
.checkBox1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.rectangleIcon {
    border-radius: 12px;
    width: 160px;
    height: 90px;
    object-fit: cover;
}
.div4,
.div5,
.rectangleIcon {
    position: relative;
}
.div4 {
    font-weight: 600;
    color: #333;
}
.div5 {
    font-size: 14px;
    display: inline-block;
    width: 294px;
}
.tag,
.wrapper {
    flex-direction: row;
}
.wrapper {
    border-radius: 13px;
    background-color: #ffe6c9;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
}
.tag {
    align-items: flex-end;
    gap: 4px;
    font-size: 10px;
}
.group,
.rectangleContainer,
.tag {
    display: flex;
    justify-content: flex-start;
}
.group {
    width: 480px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
.rectangleContainer {
    flex-direction: row;
    align-items: center;
    gap: 16px;
}
.div10,
.div11 {
    position: relative;
}
.div10 {
    text-decoration: line-through;
}
.div11 {
    font-size: 24px;
    font-weight: 600;
    color: #e42727;
}
.buttonsecondarydefault {
    border-radius: 28px;
    border: 1px solid #fe9923;
    box-sizing: border-box;
    width: 85px;
    height: 34px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 4px;
    color: #fe9923;
}
.closeIcon {
    width: 32px;
    color: #fe9923;
}
.frameParent1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    font-size: 14px;
}
.frameContainer {
    align-self: stretch;
    flex: 1;
    justify-content: space-between;
    font-size: 24px;
    color: #666;
}
.buttonsecondarydefault1,
.frameContainer,
.parent3,
.wishListCard {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.wishListCard:hover {
    background: linear-gradient(0deg, rgba(254, 153, 35, 0.03) 0%, rgba(254, 153, 35, 0.03) 100%), #FFF;
}
.wishListCard {
    border-radius: 16px;
    background-color: #fff;
    width: 1024px;
    justify-content: flex-start;
    padding: 16px 24px;
    box-sizing: border-box;
    gap: 24px;
}
.buttonsecondarydefault1,
.parent3 {
    gap: 4px;
}
.parent3 {
    justify-content: flex-start;
    color: #666;
}
.buttonsecondarydefault1 {
    border-radius: 28px;
    border: 1px solid #fe9923;
    box-sizing: border-box;
    width: 85px;
    height: 34px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 12px 24px;
}
.iconpause {
    position: relative;
    width: 12px;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}
.frameParent3,
.linkdefault {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.linkdefault {
    border-radius: 16px;
    overflow: hidden;
    justify-content: flex-start;
    padding: 2px 0;
    gap: 4px;
}
.frameParent3 {
    align-self: stretch;
    justify-content: flex-end;
    gap: 12px;
    font-size: 14px;
    color: #fe9923;
}
.wishListCard1 {
    border-radius: 16px;
    background: linear-gradient(
            rgba(254, 153, 35, 0.03),
            rgba(254, 153, 35, 0.03)
    ),
    #fff;
    width: 1024px;
    flex-direction: row;
    padding: 16px 24px;
    box-sizing: border-box;
    gap: 24px;
}
.checkBox3,
.frameGroup,
.frameParent,
.wishListCard1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.checkBox3 {
    flex-direction: row;
    gap: 8px;
    opacity: 0;
}
.frameGroup,
.frameParent {
    flex-direction: column;
}
.frameGroup {
    align-self: stretch;
    gap: 8px;
    font-size: 14px;
}
.frameParent {
    width: 1024px;
    padding-top: 32px;
    padding-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    gap: 16px;
}
.webChild {
    position: absolute;
    top: 16px;
    left: 1187px;
    border-radius: 50%;
    background-color: #e1e1e1;
    width: 45px;
    height: 45px;
    display: none;
}
.icon,
.icon1 {
    position: relative;
    width: 39.38px;
    height: 29.9px;
    overflow: hidden;
    flex-shrink: 0;
}
.icon1 {
    width: 70.33px;
    height: 28.35px;
}
.logo,
.logoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.logo {
    width: 114px;
    height: 30px;
    align-items: flex-end;
    gap: 4px;
}
.logoWrapper {
    position: absolute;
    top: calc(50% - 14.5px);
    left: 212px;
    align-items: center;
}
.cachedIcon {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}
.cachedParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.frameChild9 {
    position: relative;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.33);
    width: 2px;
    height: 24px;
}
.iconwishlist {
    position: relative;
    width: 12px;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}
.linkdefaultghostWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.webFrame245Child {
    position: relative;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    object-fit: cover;
    z-index: 0;
}
.iconUser {
    position: absolute;
    margin: 0 !important;
    top: 10.5px;
    left: 10.5px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    z-index: 1;
}
.frameParent10,
.webFrame245 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
}
.frameParent10 {
    position: absolute;
    top: calc(50% - 17.5px);
    right: 208px;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
.div,
.web {
    overflow: hidden;
    text-align: left;
    font-family: "PingFang SC";
}
.web {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fe9923;
    width: 1440px;
    height: 77px;
    font-size: 14px;
    color: #efefef;
}
.div {
    position: relative;
    background-color: #fbfbfb;
    width: 100%;
    min-width: 1024px;
    min-height: 700px;
    font-size: 32px;
    color: #333;
}
