.header {
  width: 100%;
  height: 100%;
  background-color: var(--default);
}
.web1 {
  background-color: var(--default);
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  height: 77px;
  overflow: hidden;
  font-size: var(--font-size-sm);
  color: var(--color-whitesmoke-200);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heartDivider {
  height: 24px;
  background: rgba(255, 255, 255, 0.33);
  width: 2px;
  border: 0;
}

.heartWrapper {
  display: flex;
  flex-direction: row;
  margin-right: 24px;
}
.heartSection {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 24px;
}


.headerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonsecondarydefault {
  cursor: pointer;
  border-radius: var(--br-9xl);
  color: var(--color-whitesmoke-200);
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  background-color: transparent;
}

.button {
  position: relative;
}

.menuIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  color: #666;
}

.uParent {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--font-size-21xl);
  color: var(--color-white);
}

.div25 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-weight: 600;
  font-family: PangMenZhengDaoXiXianTi;
}

.div26 {
  position: relative;
  font-size: var(--font-size-xs);
  font-weight: 600;
  margin-top: -10px;
  margin-left: 3px;
  font-family: PangMenZhengDaoXiXianTi;
}

.webFrame245 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  cursor: pointer;
}

.webFrame245Child {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gainsboro);
  width: 45px;
  height: 45px;
  z-index: 0;
}

.iconLoginUser {
  position: relative;
  margin: 0 !important;
  width: 45px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.iconUser {
  position: relative;
  margin: 0 !important;
  top: 10.5px;
  left: 10.5px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
