.web {
  position: relative;
  background-color: var(--color-whitesmoke-100);
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  /*height: 2638px;*/
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray);
}
