.web {
  position: relative;
  background-color: var(--color-whitesmoke-100);
  top: 32px;
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  /*height: 2638px;*/
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray);
}
.leftWrap {
  display: flex;
  width: 241px;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  background: #FFF;
  flex-wrap: wrap;
  padding: 24px;
}

.rightWrap {
  display: flex;
  width: 763px;
  height: 100%;
  flex-direction: column;
  gap: 32px;
  border-radius: 16px;
  background: #FFF;
  flex-wrap: wrap;
  padding: 24px;
  margin-left: 24px;
}

.menuItemWrapperSelected {
  color: #fe9923;
  background-color: #fffaf0;
}

.menuIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  color: #666;
}

.menuIconSelected {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  color: #fe9923;
}

.msgWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.genderWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 36px;
}

.checkboxWrap {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.textInput {
  width: 414px;
  border-radius: 8px !important;
}

.codeInput {
  border-radius: 28px;
  font-size: 14px;
  background-color: #efefef;
}

.row2 {
  margin-top: 16px;
  display:flex;
  flex-direction: row;
}

.clear {
  color: rgba(102, 102, 102, 0.5);
}

.codeBtn {
  margin-left: 9px;
  font-size: 14px;
  width: 118px;
  flex-shrink: 0;
  border-radius: 28px;
}

.loginBtn {
  width: 100%;
  border-radius: 28px;
  margin-top: 12px;
}

.row3 {
  margin-top: 8px;
  display:flex;
  flex-direction: row;
  color: rgba(51, 51, 51, 0.60);
}

.row4 {
  margin-top: 8px;
  display:flex;
  flex-direction: row;
  color: rgba(51, 51, 51, 0.60);
  margin-top: 12px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.content {
  font-size: 14px;
}

.errMsg {
  color: red;
}

.round {
  appearance:none;/*取消默认的复选框样式*/
	/*重新写一个复选框圆角样式*/
	width:20px;/*设置方形宽度为20px*/
	height:20px;/*设置方形高度为20px*/
	border:1px solid #aaaaaa;/*设置边框样式*/
	border-radius:50%;/*将方形的边框改为圆形*/
}

.round:checked {
	transition:all 0.3s;
	background-size:27px 27px;/*背景图片大小,第一个27px表示width宽度,第二个27px表示height高度*/
	background-position: center;/*背景图居中对齐*/
}