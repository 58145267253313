/* WishlistButton.css */
.wishlist-button {
    font-size: 24px; /* 爱心按钮本身大小 */
    border: none; /* 移除默认边框 */
    padding: 0;
    color: var(--default);
    cursor: pointer;
    width: 54px;
    margin-left: 3px;
    justify-content: center;
}

.wishlist-button:hover {
    border-radius: 27px; /* hover 灰圈大小的一半 */
    background-color: #f5f5f5; /* hover 背景色 */
    padding: 15px; /* hover 外圈大小 */
    font-size: 24px;
    cursor: pointer;
    width: unset;
}

/*.wished {*/
/*    cursor: pointer;*/
/*}*/

/*.wished:hover {*/
/*    border-radius: 27px; !* hover 灰圈大小的一半 *!*/
/*    background-color: #f5f5f5; !* hover 背景色 *!*/
/*    padding: 15px; !* hover 外圈大小 *!*/
/*    font-size: 24px;*/
/*    cursor: pointer;*/
/*}*/
