.div47 {
    position: relative;
    width: 676px;
    /*height: 840px;*/
    font-size: var(--font-size-base);
}

.expanded {
    height: 100% !important;
}

.div48 {
    /*position: absolute;*/
    top: 0;
    left: 0;
    border-radius: var(--br-base) var(--br-base) 0 0;
    background-color: var(--color-white);
    width: 676px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-base);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    transition: max-height 0.3s ease-out;
}

.div23,
.inner {
    flex-direction: row;
    align-items: center;
}

.div46 {
    /*position: absolute;*/
    cursor: pointer;
    bottom: 0;
    left: 0;
    width: 676px;
    height: 40px;
    font-size: var(--font-size-sm);
    color: var(--default);
}

.div13,
.div20,
.div23,
.inner {
    display: flex;
    justify-content: flex-start;
}

.innerRight {
    display: flex;
    justify-content: flex-end;
}

.dlc2 {
    position: relative;
    font-weight: bold;
}

.inner2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: var(--font-size-5xl);
}

.p {
    margin: 0;
}

.div2,
.rectangleParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.rectangleParent {
    gap: var(--gap-xs);
}
.item {
    bottom: 0;
    border-radius: 0 0 var(--br-base) var(--br-base);
    box-shadow: 0-2px 10px rgba(0, 0, 0, 0.05);
    width: 676px;
    height: 40px;
}
.div37,
.item {
    position: absolute;
    left: 0;
    background-color: var(--color-white);
}
.button {
    position: relative;
}
.iconDown {
    position: relative;
    width: 12px;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}
.linkdefault {
    position: absolute;
    bottom: 8px;
    left: 302px;
    border-radius: var(--br-base);
    width: 72px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs) 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.instanceChild {
    position: relative;
    border-radius: var(--br-8xs);
    background-color: var(--default);
    width: 10px;
    height: 27px;
}


.ellipseParent,
.yydsParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.yydsParent {
    flex: 1;
    flex-direction: column;
}
.ellipseParent {
    border-bottom: 1px solid var(--color-whitesmoke-300);
    flex-direction: row;
    padding: 0 0 var(--padding-xs);
    gap: var(--gap-xs);
    padding: 0px 22px;
    padding-bottom: 12px;
}
.div49,
.ellipseParent,
.like {
    align-self: stretch;
}

.userAvatar {
    width: 45px;
    height: 45px;
}

.frameItem,
.yyds {
    position: relative;
    height: 45px;
}
.frameItem {
    border-radius: 50%;
    background-color: var(--color-sandybrown-200);
    width: 45px;
}
.userWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.yyds {
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.attitudeLike {
    width: 30px;
    height: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: var(--default);
}

.attitudeDislike {
    width: 30px;
    height: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #007aff;
}

.like {
    position: relative;
    font-size: var(--font-size-sm);
    text-align: justify;
    margin-top: -2px;
    white-space: pre-line;
    word-break: break-all;
}

.purchaseButton {
    width: 100%;
    text-align: center;
}

.buttonprimarysmall {
    cursor: pointer;
    border-radius: var(--br-9xl);
    background-color: var(--default);
    width: 40px!important;
    height: 54px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-sm);
    color: var(--color-white);
}

.textContentInput {
    word-break: break-all;
    resize: none !important;
}

:global(.textContentInputWrapper.ant-input-affix-wrapper-focused) {
    border-color: #FE9923 !important;
    box-shadow: 0px 0px 2px 2px rgba(237, 176, 21, 0.20);
}

:global(.textContentInputWrapper .ant-input-data-count) {
    bottom: 6px !important;
    right: 10px;
}

.textContentExceed {
    color: #E13939 !important;
}

.attitudeWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    justify-content: space-between;
}

.attitudeLeftWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.likeHint {
    font-size: 14px;
}

.attitudeLikeBtn {
    border-radius: 16px;
    padding: 2px 10px;
    margin-left: 8px;
    color: #FE7223;
    background-color: rgba(255, 230, 201, 0.5);
}

.attitudeFrownBtn {
    border-radius: 16px;
    padding: 2px 10px;
    margin-left: 8px;
    color: #666666;
    background-color: #EFEFEF;
}

.attitudeLikeBtnDisactive {
    border-radius: 16px;
    padding: 2px 10px;
    margin-left: 8px;
    color: #666666;
    background-color: #EFEFEF;
}

.attitudeLikeBtn, .attitudeLikeBtnDisactive:hover {
    color: #FE7223 !important;
    background-color: rgba(255, 230, 201, 0.5) !important;
}

.attitudeFrownBtn:hover {
    color: #2196F3 !important;
    background-color: #E4F2FE !important;
}

.attitudeFrownBtnActive {
    border-radius: 16px;
    padding: 2px 10px;
    margin-left: 8px;
    color: #2196F3 !important;
    background-color: #E4F2FE !important;
}