.wrapper {
    display:flex;
    flex-direction: column;
}

.titleWrapper {
    display: flex;
    lex-direction: row;
    justify-content: center;
}

.title {
    align-self: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.row1 {
    display:flex;
    flex-direction: row;
    align-items: baseline;
}

.row2 {
    margin-top: 16px;
    display:flex;
    flex-direction: row;
}

.row3 {
    margin-top: 12px
}

.clear {
    color: rgba(102, 102, 102, 0.5);
}

.phonePrefix {
    font-size: 14px;
    margin-right: 9px;
}

.close {
    color: #FE9923;
}

.phoneInput {
    border-radius: 28px;
    font-size: 14px;
    background-color: #efefef;
}

.codeInput {
    border-radius: 28px;
    font-size: 14px;
    background-color: #efefef;
}

.codeBtn {
    margin-left: 9px;
    font-size: 14px;
    width: 118px;
    flex-shrink: 0;
    border-radius: 28px;
}

.loginBtn {
    border-radius: 28px;
    margin-top: 24px;
    width: 100%;
}
.term {
    font-size: 12px;
}

.logoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.button {
    width: 48px;
}
.div25 {
    position: relative;
    font-size: var(--font-size-5xl);
    font-weight: 600;
    font-family: PangMenZhengDaoXiXianTi;
}

.menuItemWrapperSelected {
    color: #fe9923;
    background-color: transparent;
}