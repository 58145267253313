body {
  -webkit-text-size-adjust: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-whitesmoke-100);
}

body {
  margin: 0;
  line-height: normal;
}

.blur {
  filter: blur(5px);
}

:root {
  /* fonts */
  --font-racing-sans-one: "Racing Sans One";
  --font-inherit: inherit;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-3xs: 9px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-21xl: 40px;

  /* Colors */
  --color-whitesmoke-100: #f7f7f7;
  --color-whitesmoke-200: #efefef;
  --color-whitesmoke-300: #ecebeb;
  --color-darkslategray: #333;
  --color-dimgray-100: #666;
  --color-dimgray-200: rgba(102, 102, 102, 0.5);
  --color-bisque: #ffe6c9;
  --default: #fe9923;
  --color-white: #fff;
  --color-sandybrown-100: #ffb156;
  --color-sandybrown-200: #ffaa46;
  --color-crimson: #e42727;
  --color-gainsboro: #e1e1e1;

  /* Gaps */
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-base: 16px;
  --gap-xl: 20px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-9xs: 4px;
  --padding-7xs: 6px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-10xs: 3px;
  --padding-11xs: 2px;
  --padding-base: 16px;
  --padding-3xl: 22px;

  /* border radiuses */
  --br-smi: 13px;
  --br-9xl: 28px;
  --br-base: 16px;
  --br-5xs: 8px;
  --br-8xs: 5px;
  --br-xs: 12px;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}