.webChild {
    position: absolute;
    top: 77px;
    left: 0;
    background: linear-gradient(180deg, #fd9230, rgba(243, 115, 53, 0));
    width: 1440px;
    height: 594px;
    display: none;
}
.maskGroupIcon {
    top: 76px;
    left: 0;
    width: 1440px;
    height: 600px;
    object-fit: cover;
    opacity: 0.8;
}
.maskGroupIcon,
.maskGroupIcon1,
.webItem {
    position: absolute;
    display: none;
}
.maskGroupIcon1 {
    top: 108px;
    left: 904px;
    width: 328px;
    height: 461px;
    opacity: 0.3;
}
.webItem {
    top: 604px;
    left: 208px;
    border-radius: var(--br-base);
    background-color: var(--color-white);
    width: 676px;
    height: 700px;
}
.frameChild {
    position: relative;
    width: 54px;
    height: 54px;
    z-index: 0;
}
.iconUp {
    position: absolute;
    margin: 0 !important;
    top: 15px;
    left: 15px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}
.buttonroundlarge,
.groupParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.groupParent {
    flex-direction: row;
    position: relative;
    gap: var(--gap-3xs);
}
.buttonroundlarge {
    position: absolute;
    top: 1148px;
    left: 1306px;
    flex-direction: column;
}
.instanceChild {
    position: relative;
    border-radius: var(--br-8xs);
    background-color: var(--default);
    width: 10px;
    height: 27px;
}

.sideRecommandContainer {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
}
.dlc2 {
    position: relative;
    font-weight: bold;
}
.div2,
.rectangleParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.rectangleParent {
    gap: var(--gap-xs);
}
.div2 {
    padding: 0 var(--padding-base);
}
.steam1 {
    position: relative;
    font-size: var(--font-size-3xs);
    color: var(--color-dimgray-200);
}
.div10,
.ign,
.steam {
    border-radius: var(--br-xs);
    background-color: var(--color-whitesmoke-100);
    overflow: hidden;
    flex-direction: column;
    align-items: center;
}
.steam {
    align-self: stretch;
    display: flex;
    padding: var(--padding-9xs) var(--padding-xs);
    justify-content: flex-start;
}
.div10,
.ign {
    flex: 1;
}
.ign {
    display: flex;
    padding: var(--padding-9xs) var(--padding-xs);
    justify-content: flex-start;
}
.div10 {
    padding: var(--padding-9xs) 8px var(--padding-9xs) var(--padding-7xs);
}
.div1,
.div10,
.div3,
.div5 {
    display: flex;
    justify-content: flex-start;
}
.div5 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-9xs);
}
.div1,
.div3 {
    flex-direction: column;
    align-items: flex-start;
}
.div3 {
    align-self: stretch;
    padding: 0 var(--padding-base);
    gap: var(--gap-5xs);
    text-align: justify;
    font-size: var(--font-size-sm);
}
.div1 {
    border-radius: var(--br-base);
    background-color: var(--color-white);
    width: 328px;
    padding: var(--padding-base) 0;
    box-sizing: border-box;
    gap: var(--gap-base);
}
.div14,
.div16 {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.div14 {
    padding: 0 var(--padding-xs);
    justify-content: flex-start;
    font-size: var(--font-size-5xl);
}
.div16 {
    align-self: stretch;
    padding: 0 var(--padding-base);
    justify-content: center;
}
.p {
    margin: 0;
}
.configRecommend {
    margin: 0 0 4px 0;
    padding: 0 var(--padding-base);
}

.div19 {
    position: relative;
    display: inline-block;
    width: 72px;
    flex-shrink: 0;
}
.windows7AnythingContainer {
    flex: 1;
    position: relative;
    color: var(--color-darkslategray);
}
.div18 {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    color: var(--color-dimgray-100);
}
.div18 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-base);
    align-items: flex-start;
}
.div20 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
}
.div13,
.div20,
.div23,
.inner {
    display: flex;
    justify-content: flex-start;
}
.div13 {
    border-radius: var(--br-base);
    background-color: var(--color-white);
    width: 328px;
    flex-direction: column;
    padding: var(--padding-base) 0;
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-base);
    font-size: var(--font-size-sm);
}
.div23,
.inner {
    flex-direction: row;
    align-items: center;
}
.div23 {
    padding: 0 var(--padding-xs) 0 var(--padding-base);
}
.dfkdffkaljj2 {
    margin-bottom: 0;
}
.dfkdffkaljj1 {
    margin: 0;
    padding-left: 19px;
}
.dfkdffkaljj {
    flex: 1;
    position: relative;
}
.div24 {
    width: 328px;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-base);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: var(--font-inherit);
}
.div22 {
    border-radius: var(--br-base);
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    padding: var(--padding-base) 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
}
.div,
.webInner {
    /*position: absolute;*/
}
.div {
    top: 530px;
    left: 696px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}
.webInner {
    top: 16px;
    left: 1187px;
    border-radius: 50%;
    background-color: var(--color-gainsboro);
    width: 45px;
    height: 45px;
    display: none;
}
.button {
    position: relative;
}

.recommandButton {
    position: relative;
    width: 95%;
    text-overflow:ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.styles.recommandIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px; /* Adjust icon size as needed */
    color: white; /* or any color you prefer */
    cursor: pointer;

    /*!* Optional: Add hover effect *!*/
    /*    &:hover {*/
    /*       color: #1890ff; !* Ant Design blue, for example *!*/
    /*     }*/
}
.purchaseButton {
    width: 100%;
    text-align: center;
}
.buttonsecondarydefault {
    cursor: pointer;
    position: absolute;
    top: 22px;
    left: 1037px;
    border-radius: var(--br-9xl);
    border: 1px solid var(--color-whitesmoke-200);
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    align-items: center;
    justify-content: center;
}
.div25 {
    position: relative;
    font-size: var(--font-size-5xl);
    font-weight: 600;
}
.uParent {
    position: absolute;
    top: 14px;
    left: 212px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--font-size-21xl);
    color: var(--color-white);
}
.webFrame245Child {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-gainsboro);
    width: 45px;
    height: 45px;
    z-index: 0;
}
.iconUser {
    position: absolute;
    margin: 0 !important;
    top: 10.5px;
    left: 10.5px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}
.webFrame245 {
    position: absolute;
    top: 16px;
    left: 1187px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}
.web1 {
    top: 0;
    left: 0;
    background-color: var(--default);
    width: 1440px;
    height: 77px;
    overflow: hidden;
    font-size: var(--font-size-sm);
    color: var(--color-whitesmoke-200);
}
.child {
    cursor: pointer;
    align-self: stretch;
    position: relative;
    border-radius: var(--br-xs) 0 0 var(--br-xs);
    max-height: 100%;
    width: 160px;
    object-fit: cover;
}
.childWrapper {
  position: relative;
  line-height: 0;
}
.childTransparent {
    opacity: 0;
    transition: all ease 220ms;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
    border-radius: 16px 16px 0px 0px;
    color: #EFEFEF;
    z-index: 1;
}
.displayChildTransparent {
    opacity: 1;
}
.childHint {
    margin-left: 3px;
}
.div33 {
    font-size: var(--font-size-base);
}
.div33,
.div34,
.div35 {
    position: relative;
}
.div34 {
    text-decoration: line-through;
    color: var(--color-dimgray-100);
}
.div35 {
    font-weight: 600;
}
.wrapper {
    border-radius: var(--br-smi);
    background-color: var(--color-sandybrown-100);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-10xs) var(--padding-7xs);
    align-items: center;
    justify-content: center;
    color: var(--color-white);
}
.div30,
.div32 {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}
.div32 {
    flex-direction: row;
    padding: 0 var(--padding-base) 0 0;
    align-items: center;
    font-size: var(--font-size-3xs);
    color: var(--color-crimson);
}
.div30 {
    width: 195px;
    flex-direction: column;
    align-items: flex-start;
}
.buttonsecondarylarge,
.div29 {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.buttonsecondarylarge {
    cursor: pointer;
    border-radius: var(--br-9xl);
    border: 1px solid var(--default);
    box-sizing: border-box;
    width: 85px;
    height: 34px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    font-size: var(--font-size-sm);
    color: var(--default);
    background-color: transparent;
}
.div29 {
    align-self: stretch;
    flex: 1;
    border-radius: 0 var(--br-base) var(--br-base) 0;
    background-color: var(--color-whitesmoke-100);
    padding: 0 var(--padding-base);
    justify-content: space-between;
}
.div27,
.div28 {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
}
.div28 {
    width: 644px;
    height: 92px;
    flex-direction: row;
    padding: 0 0 0 var(--padding-base);
    font-size: var(--font-size-base);
}
.div27 {
    border-radius: var(--br-base);
    background-color: var(--color-white);
    width: 676px;
    flex-direction: column;
    padding: var(--padding-base);
    gap: var(--gap-base);
}
.div40,
.icon {
    position: relative;
    width: 600px;
}
.div40 {
    display: inline-block;
}
.icon {
    height: 204px;
    object-fit: cover;
}
.div39 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}
.p7 {
    margin: 0;
    font-weight: 500;
}
.div38 {
    display: flex;
    flex-direction: column;
    padding: 0 38px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--font-size-sm);
}
.div37,
.item {
    /*position: absolute;*/
    left: 0;
    background-color: var(--color-white);
}
.expanded {
    max-height: 100% !important;
}
.div37 {
    top: 0;
    border-radius: var(--br-base) var(--br-base) 0 0;
    max-height: 800px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-base) 0;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
}
.item {
    bottom: 0;
    border-radius: 0 0 var(--br-base) var(--br-base);
    box-shadow: 0-2px 10px rgba(0, 0, 0, 0.05);
    width: 676px;
    height: 40px;
}
.iconDown {
    position: relative;
    width: 12px;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}
.linkdefault {
    position: absolute;
    bottom: 8px;
    left: 302px;
    border-radius: var(--br-base);
    width: 72px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs) 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}
.div46 {
    /*position: absolute;*/
    cursor: pointer;
    bottom: 0;
    left: 0;
    width: 676px;
    height: 40px;
    font-size: var(--font-size-sm);
    color: var(--default);
}
.div36 {
    position: relative;
    width: 676px;
    height: 100%;
}
.inner2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-5xl);
}
.frameItem,
.yyds {
    position: relative;
    height: 45px;
}
.frameItem {
    border-radius: 50%;
    background-color: var(--color-sandybrown-200);
    width: 45px;
}
.yyds {
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.ellipseParent,
.yydsParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.yydsParent {
    flex: 1;
    flex-direction: column;
}
.ellipseParent {
    border-bottom: 1px solid var(--color-whitesmoke-300);
    flex-direction: row;
    padding: 0 0 var(--padding-xs);
    gap: var(--gap-xs);
}
.div49,
.ellipseParent,
.like {
    align-self: stretch;
}
.div49 {
    display: flex;
    flex-direction: column;
    padding: 0 var(--padding-3xl);
    align-items: flex-start;
    justify-content: flex-start;
}
.like {
    position: relative;
    font-size: var(--font-size-sm);
    text-align: justify;
    margin-top: -2px;
}
.div48 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-base) var(--br-base) 0 0;
    background-color: var(--color-white);
    width: 676px;
    height: 800px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-base);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
}
.div47 {
    position: relative;
    width: 676px;
    height: 840px;
    font-size: var(--font-size-base);
}
.div26,
.icon3 {
    /*position: absolute;*/
}
.div26 {
    margin-top: 36px;
    top: 530px;
    left: 208px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}
.icon3 {
    top: 0;
    left: 0;
    border-radius: var(--br-base);
    width: 676px;
    height: 381px;
    object-fit: cover;
}
.frameIcon {
    position: relative;
    width: 40px;
    height: 40px;
    color: var(--default);
    background-color: var(--color-white);
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.frameChild2,
.frameChild4,
.rectangleIcon {
    position: relative;
    border-radius: var(--br-5xs);
    width: 114px;
    height: 65px;
    object-fit: cover;
}
.frameChild2,
.frameChild4 {
    width: 115px;
}
.frameChild4 {
    width: 126px;
    height: 77px;
}
.div58,
.rectangleParent3 {
    display: flex;
    flex-direction: row;
}
.rectangleParent3 {
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
}
.div58 {
    /*position: absolute;*/
    top: 397px;
    left: 0;
    width: 676px;
    align-items: center;
    justify-content: center;
    gap: 32px;
}
.div57 {
    margin-top: 32px;
}
.div57,
.div60,
.saleChild {
    /*position: absolute;*/
    top: 32px;
    left: 208px;
    width: 676px;
    height: 462px;
}
.div60,
.saleChild {
    top: 0;
    left: 0;
    border-radius: var(--br-base);
    background-color: var(--color-white);
    width: 328px;
}
.saleChild {
    border-radius: 0 var(--br-base) 0 15.5px;
    background-color: var(--color-crimson);
    width: 67px;
    height: 31px;
}
.sale,
.sale1 {
    position: absolute;
    top: 4px;
    left: 16px;
}
.sale {
    top: 0;
    left: 261px;
    width: 67px;
    height: 31px;
    font-family: var(--font-racing-sans-one);
}
.div61 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.div64 {
    position: relative;
    font-size: 32px;
    font-weight: 600;
}
.div63,
.group1 {
    display: flex;
    justify-content: flex-start;
}
.div63 {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-9xs);
    font-size: var(--font-size-sm);
}
.group1 {
    flex-direction: column;
    align-items: flex-start;
}
.gameButton {
    cursor: pointer;
    border-radius: var(--br-9xl);
    border: var(--default) 1px solid;
    width: 296px;
    height: 54px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-sm);
    color: var(--default);
}

.purchasebuttonwrap {
    display: flex;
    width: 300px;
}

.ageHintBtnGroup {
    display: flex;
    flex-direction: column;
}

.purchasebutton {
    cursor: pointer;
    border-radius: var(--br-9xl);
    background-color: var(--default);
    height: 54px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    flex-grow: 1;
}

.buttonprimarylarge {
    cursor: pointer;
    border-radius: var(--br-9xl);
    background-color: var(--default);
    width: 296px!important;
    height: 54px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-5xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-sm);
    color: var(--color-white);
}
.group {
    position: absolute;
    top: 326px;
    left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--font-size-xs);
    color: var(--color-crimson);
}
.castleMorihisa,
.div69 {
    align-self: stretch;
    position: relative;
}
.div69 {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: var(--color-darkslategray);
}
.castleMorihisa {
    font-size: var(--font-size-xs);
    color: var(--color-dimgray-100);
    text-align: justify;
}
.div68 {
    width: 245px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.frame,
.tag {
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-3xs);
}
.frame {
    border-radius: var(--br-smi);
    background-color: var(--color-bisque);
    overflow: hidden;
    padding: 3px 5px;
    align-items: center;
    justify-content: center;
}
.tag {
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: var(--font-size-3xs);
    color: var(--color-dimgray-100);
}
.parent,
.tag {
    gap: var(--gap-9xs);
}
.group2,
.parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.roguelike {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    color: var(--color-darkslategray);
    text-align: justify;
    width: 296px;
}
.div74,
.group3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}
.div74 {
    width: 296px;
    flex-direction: row;
}
.group3 {
    flex-direction: column;
    font-size: var(--font-size-xs);
    color: var(--color-dimgray-100);
}
.div59,
.div67 {
    position: absolute;
}
.div67 {
    top: 16px;
    left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
    font-size: 28px;
    color: var(--color-darkslategray);
}
.div59 {
    top: 32px;
    left: 696px;
    width: 328px;
    height: 462px;
    font-size: var(--font-size-base);
    color: var(--color-white);
}
.web {
    position: relative;
    background-color: var(--color-whitesmoke-100);
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    /*height: 2638px;*/
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--color-darkslategray);
}
