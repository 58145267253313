.webChild,
.webItem {
    position: absolute;
    top: 160px;
    left: 197px;
    border-radius: 39px;
    background-color: #fcfcfc;
    width: 1024px;
    height: 772px;
    display: none;
}
.webItem {
    top: 16px;
    left: 1187px;
    border-radius: 50%;
    background-color: #e1e1e1;
    width: 45px;
    height: 45px;
}
.button {
    position: relative;
}

.buttonDownload {
    border-radius: 28px;
    border: 1px solid #efefef;
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    align-items: center;
    justify-content: center;
}

.buttonsecondarydefault {
    position: absolute;
    top: 22px;
    left: 1037px;
    border-radius: 28px;
    border: 1px solid #efefef;
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    align-items: center;
    justify-content: center;
}
.div {
    position: relative;
    font-size: 24px;
    font-weight: 600;
}
.uParent {
    position: absolute;
    top: 14px;
    left: 212px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 40px;
    color: #fff;
}
.webFrame245Child {
    position: relative;
    border-radius: 50%;
    background-color: #e1e1e1;
    width: 45px;
    height: 45px;
    z-index: 0;
}
.iconUser {
    position: absolute;
    margin: 0 !important;
    top: 10.5px;
    left: 10.5px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}
.webFrame245 {
    position: absolute;
    top: 16px;
    left: 1187px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.web1 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fe9923;
    width: 1440px;
    height: 77px;
    overflow: hidden;
    color: #efefef;
}
.div3 {
    position: relative;
    font-weight: 600;
}
.downloadWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.hint {
    font-size: 14px;
    color: #333;
    position: relative;
    font-weight: 400;
    margin-bottom: 16px;
}

.frameChild {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;
    background-color: #fff;
    width: 1024px;
    height: 122px;
}
.frameItem {
    position: relative;
    border-radius: 12px;
    width: 160px;
    height: 90px;
    object-fit: cover;
}
.div5 {
    position: relative;
    font-size: 14px;
    color: #666;
    display: inline-block;
    width: 294px;
}
.frameWrapper,
.parent,
.rectangleGroup {
    display: flex;
    justify-content: flex-start;
}
.parent {
    width: 480px;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}
.frameWrapper,
.rectangleGroup {
    flex-direction: row;
    align-items: center;
}
.rectangleGroup {
    gap: 16px;
}
.frameWrapper {
    position: absolute;
    top: 16px;
    left: 24px;
}
.div6,
.div7 {
    position: relative;
}
.tip {
    color: #666;
    font-size: 12px;
    align-self: center;
}
.div6 {
    text-decoration: line-through;
}
.div7 {
    font-size: 24px;
    font-weight: 600;
    color: #e42727;
}
.group {
    position: absolute;
    top: 44px;
    right: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 14px;
    color: #666;
}
.rectangleParent {
    position: relative;
    width: 1024px;
    height: 122px;
}
.div2,
.inner {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.inner {
    color: #333;
}
.div2 {
    gap: 16px;
    font-size: 24px;
}
.orderStatusInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 24px;
    width: 1024px;
}

.statusIcon {

}

.div8 {
    position: relative;
    display: inline-block;
    width: 150px;
}
.radioButtonChild {
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #fe9923;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    z-index: 0;
}
.div9 {
    position: relative;
    z-index: 1;
}
.radioButtonItem {
    position: absolute;
    margin: 0 !important;
    top: 6px;
    left: 4px;
    border-radius: 50%;
    background-color: #fe9923;
    width: 8px;
    height: 8px;
    z-index: 2;
}
.radioButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 8px;
}
.radioButtonInner {
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
}
.container,
.radioButtonParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.radioButtonParent {
    flex-direction: row;
    gap: 29px;
    color: #333;
}
.container {
    flex-direction: column;
    gap: 24px;
}
.iconUser1 {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}
.iconUserParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.frameInner {
    position: relative;
    background-color: #fe9923;
    width: 1024px;
    height: 2px;
}
.purchaseButton {
    width: 100%;
    text-align: center;
}
.frameParent {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
}
.buttonprimarylarge,
.frameDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.frameDiv {
    justify-content: flex-start;
    gap: 8px;
}
.buttonprimarylarge {
    cursor: pointer;
    border-radius: 28px;
    background-color: #fe9923;
    width: 192px;
    height: 44px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 12px 24px;
    box-sizing: border-box;
    justify-content: center;
    color: #fff;
}
.div13,
.frameGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
}
.frameGroup {
    padding: 0 64px 0 0;
    color: #000;
}
.div13 {
    color: #333;
}
.div1,
.div17 {
    width: 1024px;
    display: flex;
    justify-content: flex-start;
}
.div1 {
    margin-top: 32px;
    top: 115px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 36px;
}
.div17 {
    display: flex;
    margin-right: 4px;
    top: 39px;
    font-size: 32px;
    font-weight: 600;
    color: #333;
}

.breadCrumb {
    margin-top: 40px;
    display: flex;
    top: 39px;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    width: 1024px;
    justify-content: flex-start;
}
.frameChild1 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #fe9923;
    width: 32px;
    height: 32px;
}
.div19 {
    position: absolute;
    top: 6px;
    left: 13px;
}
.ellipseParent {
    position: relative;
    width: 32px;
    height: 32px;
}
.div20 {
    position: relative;
    color: #333;
}
.frameParent1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.rectangleDiv {
    align-self: stretch;
    position: relative;
    background-color: #d9d9d9;
    height: 2px;
}
.frameContainer {
    flex: 1;
    height: 58px;
    display: flex;
    flex-direction: column;
    padding: 12px 0 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: #fff;
}
.frameChild2 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #ececec;
    width: 32px;
    height: 32px;
}
.div21 {
    position: absolute;
    top: 6px;
    left: 12px;
}
.frameParent2 {
    flex: 1;
    height: 58px;
    display: flex;
    flex-direction: column;
    padding: 12px 0 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
.div18,
.div26 {
    position: absolute;
}
.div18 {
    top: 170px;
    left: 208px;
    width: 1021px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    color: rgba(51, 51, 51, 0.6);
}
.div26 {
    top: 0;
    left: 8px;
    line-height: 32px;
}
.logo,
.wrapper {
    position: relative;
}
.wrapper {
    width: 32px;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
}
.logo {
    flex: 1;
    line-height: 32px;
    color: #fff;
    text-align: left;
}
.div25,
.web {
    overflow: hidden;
}
.div25 {
    position: absolute;
    top: 339px;
    left: 62px;
    border-radius: 4px;
    background-color: #ba5a5a;
    border: 1px dashed #5e2e2e;
    box-sizing: border-box;
    width: 131px;
    display: none;
    flex-direction: row;
    padding: 2px 15px 2px 0;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    font-size: 16px;
}
/* still remain some problem with the height.. */
.web {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 14px;
    color: #000;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1024px;
    margin-bottom: 32px;
    flex: 1 1 auto;
}

.paymentModalSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paymentInstruction {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    color: #333;
    padding-top: 24px;
    padding-bottom: 24px;
}

.paymentAmount {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    color: #E42727;
}

.paymentOrder {
    padding: 12px 0;
}

.paymentCancel {
    margin: 24px 0;
}
