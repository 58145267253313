
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /*background: #fff;*/
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    /*border-radius: var(--br-base);*/
    overflow: hidden;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 381px;
    width: 676px;
  }
  
  .mySwiper {
    width: 518px;
    height: 65px;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    border-radius: var(--br-5xs);
    overflow: hidden;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  .mySwiper-wrap {
    display: flex;
    align-items: center;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px; /* Adjust icon size as needed */
    color: white; /* or any color you prefer */
    cursor: pointer;

/*!* Optional: Add hover effect *!*/
/*    &:hover {*/
/*       color: #1890ff; !* Ant Design blue, for example *!*/
/*     }*/
  }
