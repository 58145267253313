.copyright20003000HhhhhhhWrapper {
    background-color: #e5d7c6;
    width: 1024px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-bottom: 22px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: justify;
    font-size: var(--font-size-xs);
    margin-left: auto;
    margin-right: auto;
}

.footer {
    width: 100%;
    background-color: #e5d7c6;
}

.button {
    position: relative;
}
